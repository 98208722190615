body{
  background: #F7F8F9;
}
.heart {
  animation: beat .7s infinite alternate;
}

@keyframes beat{
  to { transform: scale(1.2); }
}

.rc-slider-track {
  background-color: #FFAC30 !important;
}

* {
  -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
  color-adjust: exact !important;                 /* Firefox 48 – 96 */
  print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
}